import schttp from 'public/src/services/schttp/index'
import { InitFtoken } from 'public/src/pages/common/ftoken/index.js'

export function getFtoken() {
  return new Promise((resolve) => {
    if (window?.gbCommonInfo?.ftoken)
      return resolve(window?.gbCommonInfo?.ftoken)
    InitFtoken((data) => {
      resolve(data)
    }, false)
  })
}

function requestPromise(type, url, params, oheaders = {}, options = {}) {
  const headers = { 'x-ftoken': window.gbCommonInfo.ftoken, ...oheaders }
  const method = type.toLowerCase()
  const soptions = { method: type, url, headers, ...options }
  if (method == 'get') soptions.params = params
  else soptions.data = params

  return schttp(soptions).catch((err) => ({ code: -1, msg: err.message }))
}

export async function request(type, url, params, headers = {}, options = {}) {
  await getFtoken()
  const data = await requestPromise(type, url, params, headers, options)
  return data
}

