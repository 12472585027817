import { request } from 'public/src/pages/login/service.js'


export async function riskSendMsg(params) {
  const data = await request('post', '/api/risk/checkout/sendMsg/get', params)
  return data
}


export async function riskValidateCode(params) {
  const data = await request('post', '/api/risk/checkout/validateCode/get', params)
  return data
}

export async function riskCheckMsg(params) {
  const data = await request('post', '/api/risk/geetest/checkMsg/get', params)
  return data
}


// 风控验证码发送
export async function riskSendCodeSer(params) {
  const data = await request('post', `/api/user/center/sendRiskCode/get`, params)
  return data
}

// 风控验证码验证
export async function riskCodeVerifySer(params) {
  // verifyRiskCode
  const data = await request('post', `/api/user/center/verifyRiskCode/update`, params)
  return data
}

// 风控挑战更新
export async function updateRiskChalleageSer(params) {
  const data = await request('post', `/api/auth/riskChalleage/update`, params)
  return data
}

