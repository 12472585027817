<template>
  <ul
    v-show="!!recommendEmailList.length"
    class="recommend-email-wrap"
  >
    <li
      v-for="(email, i) in recommendEmailList" 
      :key="`${email}__${i}`"
      @click.stop.prevent="handleSelect(email)"
    >
      {{ email }}
    </li>
  </ul>
</template>

<script>
import analysisIndex from '../../analysis/index'
const { sensorsSend } = analysisIndex
const baseRecommendEmails = [
  'gmail.com',
  'hotmail.com',
  'yahoo.es',
  'hotmail.es',
  'yahoo.com',
  'telefonica.net',
  'outlook.com',
  'msn.com',
  'live.com',
  'icloud.com'
]
export default {
  name: 'EmailRecommendInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    isFocus: Boolean,
    forbiddenRecommend: Boolean
  },
  computed: {
    isVisible () {
      return !!this.recommendEmailList?.length
    },
    recommendEmailList: {
      set () {},
      get () {
        const atReg = /(.*)@(.*)/
        const { isFocus, value = '', forbiddenRecommend } = this
        if (!isFocus || !value || !!forbiddenRecommend) return [] // 无内容不展示
        const matchAt = value.match(atReg)
        if (!!baseRecommendEmails.find(v => v === matchAt?.[2])) return [] // 已属于推荐邮箱则不展示
        if (matchAt && !matchAt?.[1]) return [] // 以@开头不展示推荐
        return baseRecommendEmails.map(domain => {
          if (!matchAt) return `${value}@${domain}` // 没有@则完整填充
          if (domain.indexOf(matchAt?.[2]) === 0) return `${matchAt[1]}@${domain}`
          return false
        }).filter(Boolean)
      }
    }
  },
  watch: {
    isVisible (visible) {
      if (visible) {
        sensorsSend('2-8-92')
      }
    }
  },
  methods: {
    handleSelect (email = '') {
      sensorsSend('2-8-93', { suffix: email?.replace(/.*@/, '') })
      this.$emit('input', email?.trim() || '')
    }
  }
}
</script>

<style lang="less" scoped>
.recommend-email-wrap {
  list-style: none;
  max-height: 352/75rem;
  z-index: @zindex-transform1 + 1;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  margin-top: 8/75rem;
  padding: 16/75rem 0;
  box-shadow: 0 2/75rem 8/75rem 6/75rem rgba(0,0,0,0.08);
  background-color: @sui_color_white;
  overflow: auto;
  .font-dpr(26px);
  color: @sui_color_gray_dark1;
  li {
    line-height: 1.2;
    list-style: none;
    .flexbox();
    .space-between();
    .align-center();
    cursor: pointer;
    background-color: @sui_color_white;
    padding: 24/75rem 32/75rem;
    &:active {
      background-color: @sui_color_gray_weak2;
    }
  }
}
</style>
